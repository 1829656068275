import React from 'react';
import classnames from 'classnames';
import { Field, useFormikContext } from 'formik';

const TextArea = ({ name, label, required }) => {
  const { errors, touched } = useFormikContext();

  return (
    <div className={classnames('field', { error: touched[name] && !!errors[name] })}>
      <label htmlFor={name}>
        {label}
        {required ? '*' : ''}
      </label>
      <Field component="textarea" rows={8} id={name} name={name} />
      <div className="error-msg">{touched[name] && errors[name]}</div>
    </div>
  );
};

export default TextArea;
