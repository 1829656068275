import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { errorMessages } from '../../../util';

const CheckboxOther = ({ name, label }) => {
  const [checked, setChecked] = useState(false);
  const { setFieldValue, unregisterField } = useFormikContext();

  return (
    <div className="checkbox-container">
      <div className="checkbox-row">
        <input
          id={name}
          className="checkbox"
          type="checkbox"
          checked={checked}
          onChange={(event) => {
            const check = !!event.target.checked;
            if (!check) {
              unregisterField(name);
            }
            setFieldValue(name, '');
            setChecked(check);
          }}
        />
        <label className="checkbox-label" htmlFor={name}>
          {label}
        </label>
      </div>

      {checked && (
        <div className="checkbox-input">
          <Field
            type="text"
            name={name}
            validate={(value) => {
              if (!value) {
                return errorMessages.required;
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CheckboxOther;
