import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import { useFormikContext } from 'formik';

export function CheckboxGroup({ name, otherName, options, children }) {
  const { errors, touched } = useFormikContext();

  return (
    <div className="checkbox-group" role="group" aria-labelledby="checkbox-group">
      {options?.map((option, i) => (
        <Checkbox name={name} label={option.label} valueKey={option.valueKey} key={i} />
      ))}
      {children}
      <div className="error-msg">{(touched[name] && errors[name]) || (touched[otherName] && errors[otherName])}</div>
    </div>
  );
}
