import React from 'react';
import { Field } from 'formik';

const Checkbox = ({ name, valueKey, label }) => {
  return (
    <div className="checkbox-container checkbox-row">
      <Field id={valueKey} className="checkbox" type="checkbox" name={name} value={valueKey} />
      <label className="checkbox-label" htmlFor={valueKey}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
