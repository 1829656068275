import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { CheckboxGroup, CheckboxOther, InputField, TextArea } from '../Form';
import { graphql, navigate } from 'gatsby';
import { sanitizer, errorMessages, useCSSMotion } from '../../util';
import JumpToLinks from '../JumpToLinks/JumpToLinks';
import BackButton from '../BackButton/BackButton';
import './VaspForm.scss';

const VaspFormSchema = Yup.object().shape(
  {
    firstName: Yup.string().required(errorMessages.required).label('First Name'),
    lastName: Yup.string().required(errorMessages.required),
    email: Yup.string().email(errorMessages.email).required(errorMessages.required),
    companyName: Yup.string().required(errorMessages.required),
    businessActivities: Yup.array().when('businessActivitiesOther', {
      is: (value) => !value,
      then: Yup.array().min(1, 'Select at least 1 item or fill out "Other"').required(errorMessages.required),
      otherwise: Yup.array(),
    }),
    goals: Yup.string().required(errorMessages.required),
  },
  ['businessActivities', 'businessActivitiesOther'],
);

function JumpToLinksWrapper({ hashLinks, containerRef }) {
  const motionState = useCSSMotion(containerRef);
  return <JumpToLinks hashLinks={hashLinks} motionState={motionState} />;
}

const submitFormData = (formData) =>
  fetch('/api/functions/submit-vasp-form', {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });

const VaspForm = ({ data }) => {
  const { title, successRedirectLink, applicationOverviewCopy, backButtonLink } = data;
  const [submitError, setSubmitError] = useState(false);
  const containerRef = useRef(null);

  return (
    <div className="VaspForm" ref={containerRef}>
      <div className="sidenav">
        <BackButton ref={containerRef} url={backButtonLink?.url} title={backButtonLink?.title} />
        <JumpToLinksWrapper
          containerRef={containerRef}
          hashLinks={[
            {
              id: 'ApplicationOverview',
              jumpToLinkTitle: 'Application Overview',
            },
            {
              id: 'TheBasics',
              jumpToLinkTitle: 'The Basics',
            },
          ]}
        />
      </div>

      <div className="content">
        <h1 className="title">{title}</h1>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            businessActivities: [],
            businessActivitiesOther: '',
            goals: '',
          }}
          validationSchema={VaspFormSchema}
          onSubmit={async (values) => {
            try {
              setSubmitError(false);
              const response = await submitFormData(values);
              if (response.status && response.status === 200) {
                navigate(successRedirectLink.url, {
                  replace: true,
                });
              } else {
                setSubmitError(true);
              }
            } catch (err) {
              console.error(err);
              setSubmitError(true);
            }
          }}
        >
          {({ errors, submitCount, isValid, isSubmitting }) => (
            <Form className="form-style form-contained">
              <div id="ApplicationOverview" className="form-section">
                <h2 className="form-section-title">Application Overview</h2>
                <div
                  className="form-section-description"
                  dangerouslySetInnerHTML={{ __html: sanitizer(applicationOverviewCopy, true, {}) }}
                />
              </div>

              <div id="TheBasics" className="form-section">
                <h2 className="form-section-title">The Basics</h2>
              </div>

              <div className="field-group">
                <InputField error={errors.firstName} name="firstName" label="First Name" required />
                <InputField error={errors.lastName} name="lastName" label="Last Name" required />
              </div>
              <div className="field-group">
                <InputField error={errors.email} name="email" label="Email" required />
                <InputField error={errors.companyName} name="companyName" label="Company Name" required />
              </div>
              <p className="field-group-title">Expected business activities. Select all that apply. *</p>
              <CheckboxGroup
                name="businessActivities"
                otherName="businessActivitiesOther"
                error={errors.businessActivities}
                options={[
                  {
                    label: 'Wallet Custodian',
                    valueKey: 'WalletCustodian',
                  },
                  {
                    label: 'Exchange',
                    valueKey: 'Exchange',
                  },
                  {
                    label: 'Platform Development Services',
                    valueKey: 'PlatformDevelopmentServices',
                  },
                  {
                    label: 'Payment Processing Services',
                    valueKey: 'PaymentProcessingServices',
                  },
                  {
                    label: 'Merchant Services',
                    valueKey: 'MerchantServices',
                  },
                ]}
              >
                <CheckboxOther name={'businessActivitiesOther'} label="Other" />
              </CheckboxGroup>
              <div className="field-group">
                <TextArea name="goals" label="Goals" required />
              </div>
              <div className="form-controls">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  aria-disabled={isSubmitting}
                  className={classnames('submit-button', { processing: isSubmitting })}
                >
                  {isSubmitting ? 'Processing...' : 'Submit'}
                </button>
              </div>
              {submitCount > 0 && !isValid && (
                <div className="form-error">
                  <div className="label">Error</div>
                  <div className="message">Please check all required fields.</div>
                </div>
              )}
              {submitError && (
                <div className="form-error">
                  <div className="label">Error</div>
                  <div className="message">
                    There has been an issue with the form submission. Please try again or contact us directly.
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default VaspForm;

export const fragments = graphql`
  fragment VaspForm on WpPage_Flexiblelayout_FlexibleChildren_VaspForm {
    title
    backButtonLink {
      ...AcfLink
    }
    successRedirectLink {
      ...AcfLink
    }
    applicationOverviewCopy
  }

  fragment VaspForm_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_VaspForm {
    title
    backButtonLink {
      ...AcfLink
    }
    successRedirectLink {
      ...AcfLink
    }
    applicationOverviewCopy
  }
`;
